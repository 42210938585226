

import {Component, Vue} from "vue-property-decorator";
import UyapBagliDegilView from "@/views/eicrapro/UyapBagliDegilView.vue";
import UyapTakipHashList from "@/components/lists/UyapTakipHashList.vue";

@Component({
  components: {UyapTakipHashList, UyapBagliDegilView}
})
export default class HashListView extends Vue {
}
